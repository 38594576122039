.cr-navbar {
 background-color: black;
 padding: 24px 48px;
 color: #fff;
}

.cr-navbar a {
 margin-left: 24px;
 color: white;
 opacity: 0.92;
 text-decoration: none;
 font-weight: bold;
}

.cr-navbar a:hover {
 opacity: 1;
}

.cr-navbar-logo-icon {
 width: 20px;
 margin-right: 8px;
}

.cr-navbar-logo {
 font-weight: bold;
 font-size: 24px;
 margin-right: 8px;
}

.cr-navbar-connect {
 background-color: blue;
 border: none;
 padding: 12px 16px;
 border-radius: 999px;
 font-weight: bold;
 color: white;
}

.cr-navbar-connect:hover {
 opacity: 0.92;
}

.cr-navbar-connect:active {
 background-color: #33e312;
 opacity: 0.8;
}

.cr-navbar-wallet-info {
 background-color: #222;
 border-radius: 8px;
 padding: 8px 12px;
}

.cr-navbar-wallet-info-avatar {
 border-radius: 999px;
 overflow: hidden;
 width: 16px;
 height: 16px;
 margin-right: 4px;
}

@media (max-width: 504px) {
 .cr-navbar a {
  font-size: 14px;
  margin-left: 12px;
 }
}