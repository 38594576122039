.cr-homescene {

}

.cr-homescene-banner {
    background-color: black;
    padding-top: 32px;
    padding-bottom: 48px;
    padding-left: 48px;
    padding-right: 48px;
    color: white;
}

.cr-homescene-title {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 20px;
}

.cr-homescene-subtitle {
    /*font-weight: bold;*/
    opacity: 0.87;
    line-height: 1.6;
}

.cr-homescene-countdown {
    font-weight: bold;
    opacity: 0.87;
    line-height: 1.6;
    margin-top: 16px;
    font-size: 20px;
}

.cr-homescene-countdown-price {
    color: rgb(150, 150, 255);
}

.cr-homescene-countdown-time {
    color: green;
}

.cr-homescene-showroom {
    padding-top: 48px;
    padding-bottom: 64px;
}

.cr-homescene-marquee-img {
    width: 256px;
}

.cr-homescene-socials {
    padding-bottom: 64px;
    padding-left: 16px;
    padding-right: 16px;
}

.cr-homescene-info-title {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 16px;
    text-align: center;
}

.cr-homescene-discord-link {
    color: #7289DA;
    text-decoration: none;
}
.cr-homescene-discord-link:hover {
    text-decoration: underline;
}

.cr-homescene-twitter-link {
    color: #1DA1F2;
    text-decoration: none;
}
.cr-homescene-twitter-link:hover {
    text-decoration: underline;
}

.cr-homescene-mint-input-container {
    font-family: 'Inter', sans-serif;
    border-radius: 8px;
    margin-right: 8px;
    border: none;
    padding: 4px 8px;
    background-color: white;
}

.cr-homescene-mint-input {
    font-family: 'Inter', sans-serif;
    border-radius: 8px;
    margin-right: 8px;
    border: none;
    padding: 4px 12px;
}

.cr-homescene-mint-disabled {
    background-color: #555;
    border: none;
    padding: 12px 16px;
    border-radius: 8px;
    font-weight: bold;
    color: #888;
}

.cr-homescene-mint {
    background-color: #33e312;
    border: none;
    padding: 12px 16px;
    border-radius: 8px;
    font-weight: bold;
    color: white;
}

.cr-homescene-mint:hover {
    opacity: 0.92;
}

.cr-homescene-mint:active {
    opacity: 0.8;
}

.cr-homescene-connect {
    background-color: blue;
    border: none;
    padding: 12px 16px;
    border-radius: 8px;
    font-weight: bold;
    color: white;
}

.cr-homescene-connect:hover {
    opacity: 0.92;
}

.cr-homescene-connect:active {
    opacity: 0.8;
}

@media (max-width: 480px) {
    .cr-homescene-title {
        font-size: 32px;
    }
    .cr-homescene-info-title {
        font-size: 32px;
    }
    .cr-homescene-marquee-img {
        width: 200px;
    }
}